import React from 'react'
import Layout from '../components/layout'

import Displate from '../images/shop/displate.png'
import Monolith from '../images/shop/monolith.jpg'
import Yesswear from '../images/shop/yesswear.png'
import Mizu from '../images/shop/mizu.jpg'

const Shop = () => (
  <Layout>
    <h1>Shop</h1>
    <p>Support Darkroom Lab by checking out our work.</p>
    <br></br>
    <div id="shop">
      <div className="shopCard">
        <img src={Displate} alt="displate gallery"/>
        <div className="titleCard">
          <span>
          <h1>Displate</h1>
          <h2>ART</h2>
          </span>
        </div>
        <div className="buttonCard">
          <a target="_blank" rel="noopener noreferrer" href="https://displate.com/justvasili/jazzy-vibes?art=5b94f1fee8970">
            <button>
              Explore
            </button>
          </a>
          <a target="_blank" rel="noopener noreferrer" href="https://displate.com//displate/653328?art=5b94f1fee8970">
            <button>
              Buy
            </button>
          </a>
        </div>
      </div>
      <div className="shopCard">
        <img src={Monolith} alt="monolith music gallery"/>
        <div className="titleCard">
          <span>
          <h1>Monolith</h1>
          <h2>MUSIC</h2>

          </span>
        </div>
        <div className="buttonCard">
          <a target="_blank" rel="noopener noreferrer" href="https://www.youtube.com/channel/UC4IrptxENHzpZiOlIGSgPLQ">
            <button>
              Explore
            </button>
          </a>
          <a target="_blank" rel="noopener noreferrer" href="https://monolithworldline.bandcamp.com/">
            <button>
              Buy
            </button>
          </a>
        </div>
      </div>
      <div className="shopCard">
        <img src={Mizu} alt="mizu music gallery"/>
        <div className="titleCard">
          <span>
          <h1>Mizu</h1>
          <h2>MUSIC</h2>

          </span>
        </div>
        <div className="buttonCard">
          <a target="_blank" rel="noopener noreferrer" href="https://mizu1.bandcamp.com/community">
            <button>
              Explore
            </button>
          </a>
          <a target="_blank" rel="noopener noreferrer" href="https://mizu1.bandcamp.com/">
            <button>
              Buy
            </button>
          </a>
        </div>
      </div>
      <div className="shopCard">
        <img src={Yesswear} alt="yesswear music gallery"/>
        <div className="titleCard">
          <span>
          <h1>Yesswear</h1>
          <h2>MUSIC</h2>

          </span>
        </div>
        <div className="buttonCard">
          <a target="_blank" rel="noopener noreferrer" href="https://yesswear.bandcamp.com/community">
            <button>
              Explore
            </button>
          </a>
          <a target="_blank" rel="noopener noreferrer" href="https://yesswear.bandcamp.com/">
            <button>
              Buy
            </button>
          </a>
        </div>
      </div>
    </div>
  </Layout>
)

export default Shop